import React, { useState, useEffect } from 'react';
import { Loader } from 'lucide-react';
import Progress from './components/Progress';

const LoadingPage = ({ progress, stage }) => {
  const [funFact, setFunFact] = useState('');

  const getLoadingMessage = () => {
    if (progress < 10) return "최애의 능력치를 분석 중...";
    if (progress < 20) return "최애의 행운 에너지를 모으는 중...";
    if (progress < 30) return "최애의 사진을 바라보며 영감을 얻는 중...";
    if (progress < 40) return "최애의 목소리로 AI를 깨우는 중...";
    if (progress < 50) return "최애의 애교로 시스템을 달래는 중...";
    if (progress < 60) return "최애의 댄스 동작을 학습하는 중...";
    if (progress < 70) return "최애의 팬심으로 알고리즘을 강화하는 중...";
    if (progress < 80) return "최애의 매력으로 숫자를 정렬하는 중...";
    if (progress < 90) return "최애의 사인으로 마무리 터치를 하는 중...";
    return "곧 최애의 번호가 탄생합니다!";
  };

  const getFunFact = () => {
    const facts = [
      "알고 계셨나요? 로또 번호는 무작위로 추첨되지만, 우리의 마음은 최애를 향해 일직선이죠!",
      "재미있는 사실: 로또에 당첨될 확률보다 아이돌과 데이트할 확률이 더 높다고 합니다. 하지만 우리에겐 꿈이 있죠!",
      "TMI: 세계에서 가장 큰 로또 당첨금은 무려 19억 달러였답니다. 우리의 최애 사랑도 그만큼 크겠죠?",
      "궁금하셨나요? 많은 로또 당첨자들이 자신의 행운의 숫자로 최애의 생일을 사용한다고 해요!",
      "잠깐! 로또 번호를 고르는 데에는 평균 6초가 걸린다고 합니다. 최애를 고르는 데는 얼마나 걸렸나요?",
    ];
    return facts[Math.floor(Math.random() * facts.length)];
  };

  useEffect(() => {
    setFunFact(getFunFact());
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-purple-500 to-indigo-600 text-white p-4">
      <h1 className="text-4xl font-bold mb-6 text-center text-yellow-300">최애의 번호</h1>
      <div className="bg-white rounded-lg p-8 shadow-lg max-w-md w-full">
        <div className="flex items-center justify-center mb-4">
          <Loader className="animate-spin text-purple-600 mr-2" size={32} />
          <span className="text-xl font-semibold text-purple-600">AI 모델 로딩 중...</span>
        </div>
        <Progress value={progress} className="w-full mb-4" />
        <p className="text-center text-gray-600 mb-2">
          {getLoadingMessage()}
        </p>
        <p className="text-center text-sm text-gray-500 mb-4">
          {stage || "잠시만 기다려주세요. 최애의 번호를 생성하기 위한 준비를 하고 있습니다."}
        </p>
        <div className="bg-purple-100 rounded-lg p-4 mt-4">
          <p className="text-sm text-purple-800 italic">{funFact}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;