import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import * as LucideIcons from 'lucide-react';
import Alert from './components/Alert.js';
import Button from './components/Button';
import Card from './components/Card';
import Progress from './components/Progress';
import LoadingPage from './LoadingPage';
import { useAuth } from './hooks/useAuth';
import { useFirestore } from './hooks/useFirestore';
import { useDarkMode } from './hooks/useDarkMode';
import ShareOptions from './components/ShareOptions';
import LotteryNumbers from './components/LotteryNumbers';
import AdBanner from './components/AdBanner';

const KakaoAd = React.memo(({ unit, width, height }) => {
  const adRef = useRef(null);
  const [isNoAd, setIsNoAd] = useState(false);

  useEffect(() => {
    const loadKakaoAdScript = () => {
      return new Promise((resolve, reject) => {
        if (window.kakao) {
          resolve();
        } else {
          const script = document.createElement('script');
          script.src = "https://t1.daumcdn.net/kas/static/ba.min.js";
          script.async = true;
          script.onload = () => resolve();
          script.onerror = (error) => reject(new Error(`Script load error: ${error}`));
          document.body.appendChild(script);
        }
      });
    };

    const initializeAd = async () => {
      try {
        await loadKakaoAdScript();
        if (adRef.current) {
          const ins = document.createElement('ins');
          ins.className = 'kakao_ad_area';
          ins.style.display = 'none';
          ins.setAttribute('data-ad-unit', unit);
          ins.setAttribute('data-ad-width', width);
          ins.setAttribute('data-ad-height', height);
          adRef.current.appendChild(ins);

        }
      } catch (error) {
        console.error('Ad initialization error:', error);
        setIsNoAd(true);
      }
    };

    initializeAd();

    return () => {
      // Clean up logic if needed
    };
  }, [unit, width, height]);

  if (isNoAd) {
    return (
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
          color: '#666',
          fontSize: '14px',
          textAlign: 'center'
        }}
      >
        광고를 불러올 수 없습니다.
        <br />
        잠시 후 다시 시도해 주세요.
      </div>
    );
  }

  return <div ref={adRef} />;
});

const App = () => {
  const [image, setImage] = useState(null);
  const [lotteryNumbers, setLotteryNumbers] = useState(null);
  const [error, setError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isModelLoading, setIsModelLoading] = useState(true);
  const [isModelReady, setIsModelReady] = useState(false);
  const [modelLoadingProgress, setModelLoadingProgress] = useState(0);
  const [modelLoadingStage, setModelLoadingStage] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [showRecords, setShowRecords] = useState(false);
  const [isLoadingRecords, setIsLoadingRecords] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [lotteryType, setLotteryType] = useState('lotto');
  const [infoMessage, setInfoMessage] = useState(null);
  const fileInputRef = useRef(null);
  const [worker, setWorker] = useState(null);
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);

  const { user, login, logout } = useAuth();
  const { fetchRecords, addRecord, records } = useFirestore(user);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const [isMobile, setIsMobile] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    const useragt = navigator.userAgent.toLowerCase();
    const isInApp = useragt.match(/kakaotalk|line|snapchat|wirtschaftswoche|thunderbird|instagram|everytimeapp|whatsapp|electron|wadiz|aliapp|zumapp|kakaostory|band|twitter|daumapps|daumdevice\/mobile|fb_iab|fb4a|fban|fbios|fbss/i);
    const isNaverBrowser = useragt.includes('naver');

    if (isInApp) {
      setIsInAppBrowser(true);
    }

    if (useragt.match(/kakaotalk/i)) {
      const targetUrl = encodeURIComponent(window.location.href);
      window.location.href = `kakaotalk://web/openExternal?url=${targetUrl}`;
    } else if (useragt.match(/line/i)) {
      const targetUrl = window.location.href;
      window.location.href = targetUrl + (targetUrl.includes('?') ? '&' : '?') + 'openExternalBrowser=1';
    }
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // 768px 이하를 모바일로 간주
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    console.log('Worker 초기화 시작');

    const modelWorker = new Worker('/modelWorker.js');
    setWorker(modelWorker);

    modelWorker.onmessage = (event) => {
      console.log('Worker로부터 메시지 수신:', event.data);
      if (event.data.type === 'loaded') {
        console.log('모델 로딩 완료');
        setIsModelReady(true);
        setIsModelLoading(false);
        setModelLoadingProgress(100);
      } else if (event.data.type === 'progress') {
        console.log('모델 로딩 진행률:', event.data.progress);
        setModelLoadingProgress(Math.round(event.data.progress));
      } else if (event.data.type === 'error') {
        console.error('Worker 에러:', event.data.error);
        setError('모델 로딩에 실패했습니다. 페이지를 새로고침하거나 잠시 후 다시 시도해주세요.');
        setIsModelLoading(false);
        setModelLoadingProgress(0);
      } else if (event.data.type === 'tfVersion') {
        console.log('TensorFlow.js 버전:', event.data.version);
      } else if (event.data.type === 'loadingStage') {
        console.log('로딩 단계:', event.data.stage);
        setModelLoadingStage(event.data.stage);
      }
    };

    modelWorker.onerror = (error) => {
      console.error('Worker 에러 발생:', error);
      setError('Worker 초기화 중 오류가 발생했습니다. 페이지를 새로고침해 주세요.');
      setIsModelLoading(false);
    };

    console.log('모델 로딩 시작 메시지 전송');
    modelWorker.postMessage({ type: 'load' });

    return () => {
      console.log('Worker 종료');
      modelWorker.terminate();
    };
  }, []);

  const handleExternalBrowser = () => {
    const targetUrl = window.location.href;
    navigator.clipboard.writeText(targetUrl).then(() => {
      alert('URL주소가 복사되었습니다.\n\n외부 브라우저가 열리면 주소창을 길게 터치한 뒤, "붙여놓기 및 이동"를 누르면 정상적으로 이용하실 수 있습니다.');
      window.location.href = 'x-web-search://?';
    });
  };


  const resetState = useCallback(() => {
    setImage(null);
    setLotteryNumbers(null);
    setError(null);
    setIsGenerating(false);
    setProgress(0);
    setInfoMessage(null);
  }, []);

  const generateLotteryNumbers = useCallback(async (file) => {
    console.log('번호 생성 프로세스 시작');
    console.log('현재 선택된 복권 타입:', lotteryType);

    if (!isModelReady || !worker) {
      console.error('모델이 아직 준비되지 않음. 현재 모델 상태:', { isModelReady, isModelLoading });
      setError('모델이 아직 준비되지 않았습니다. 잠시 후 다시 시도해주세요.');
      return;
    }

    setIsGenerating(true);
    setProgress(0);

    try {
      let generatedNumbers;

      if (lotteryType === 'pension') {
        console.log('연금복권 번호 생성 시작');
        const group = Math.floor(Math.random() * 5) + 1;
        const number = Math.floor(Math.random() * 1000000);
        generatedNumbers = [`${group}조`, number.toString().padStart(6, '0')];
        console.log('생성된 연금복권 번호:', generatedNumbers);
      } else {
        console.log('로또 번호 생성 시작');
        const img = new Image();
        const imageLoadPromise = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });

        await imageLoadPromise;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 224;
        canvas.height = 224;
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 224, 224);

        const imageData = ctx.getImageData(0, 0, 224, 224);

        worker.postMessage({
          type: 'classify',
          imageData: new Uint8Array(imageData.data),
          width: imageData.width,
          height: imageData.height
        });

        const results = await new Promise((resolve, reject) => {
          worker.onmessage = (event) => {
            if (event.data.type === 'results') {
              resolve(event.data.results);
            } else if (event.data.type === 'error') {
              reject(new Error(event.data.error));
            }
          };
        });

        console.log('이미지 분류 완료. 결과:', results);

        const hash = results.slice(0, 3).map(r => r.className).join(',');
        const numbers = new Set();

        while (numbers.size < 6) {
          for (let i = 0; i < hash.length; i++) {
            const index = (hash.charCodeAt(i) + hash.charCodeAt((i + 1) % hash.length)) % 45 + 1;
            numbers.add(index);
            if (numbers.size === 6) break;
          }
          // 해시로 생성된 번호가 6개 미만일 경우, 나머지는 랜덤 생성
          if (numbers.size < 6) {
            numbers.add(Math.floor(Math.random() * 45) + 1);
          }
        }

        generatedNumbers = Array.from(numbers).sort((a, b) => a - b);
      }

      console.log('생성된 번호:', generatedNumbers);
      setLotteryNumbers(generatedNumbers);
      setIsGenerating(false);
      setProgress(100);
      setShowNotice(true);

      if (user) {
        console.log('사용자 로그인 확인. 기록 저장 시도');
        try {
          await addRecord(generatedNumbers);
          console.log('기록 저장 성공');
        } catch (error) {
          console.error('기록 저장 실패:', error);
          setError('기록 저장에 실패했습니다.');
        }
      }

      console.log('번호 생성 프로세스 완료');
    } catch (error) {
      console.error('번호 생성 중 오류 발생:', error);
      console.error('오류 스택:', error.stack);
      setError('번호 생성 중 오류가 발생했습니다. 다시 시도해주세요.');
      setIsGenerating(false);
    }
  }, [lotteryType, isModelReady, worker, user, addRecord]);

  const InfoMessage = ({ message }) => (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 flex items-start">
      <LucideIcons.Info className="flex-shrink-0 mr-2 mt-1" />
      <p>{message}</p>
    </div>
  );

  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('이미지 업로드 시작:', file.name);
      console.log('현재 선택된 복권 타입:', lotteryType);
      setShowNotice(false);
      if (file.size > 10 * 1024 * 1024) {
        console.warn('이미지 크기 초과:', file.size);
        setError("이미지 크기가 너무 큽니다. 10MB 이하의 이미지를 업로드해주세요.");
        return;
      }
      setImage(URL.createObjectURL(file));
      setError(null);
      setLotteryNumbers(null);
      setInfoMessage("참고: 업로드된 이미지는 서버에 저장되지 않으며, 번호 생성 후 즉시 삭제됩니다.");
      if (isModelReady) {
        console.log('모델 준비 완료. 번호 생성 시작');
        generateLotteryNumbers(file);
      } else {
        console.log('모델이 아직 준비되지 않음');
        setError("모델 로딩 중입니다. 잠시 후 다시 시도해주세요.");
      }
    }
  }, [isModelReady, generateLotteryNumbers, lotteryType]);

  const handleNewImageUpload = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const copyToClipboard = useCallback(() => {
    if (lotteryNumbers) {
      const numbersText = lotteryType === 'lotto'
        ? lotteryNumbers.join(', ')
        : `${lotteryNumbers[0]} ${lotteryNumbers[1]}`;
      navigator.clipboard.writeText(numbersText).then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    }
  }, [lotteryNumbers, lotteryType]);

  const shareResults = useCallback(() => {
    if (lotteryNumbers) {
      setShowShareOptions(true);
    }
  }, [lotteryNumbers]);

  const toggleRecords = useCallback(() => {
    if (user) {
      setShowRecords(prev => !prev);
      if (!showRecords) {
        fetchRecords();
      }
    } else {
      setError('기록을 조회하려면 로그인이 필요합니다.');
    }
  }, [user, showRecords, fetchRecords]);

  const handleLotteryTypeChange = useCallback((newType) => {
    setLotteryType(newType);
    resetState();
  }, [resetState]);

  const mainContent = useMemo(() => (
    <div className="w-full max-w-2xl mx-auto bg-white/10 p-6 rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold mb-6 text-center text-yellow-300">최애의 번호</h1>

      {infoMessage && <InfoMessage message={infoMessage} />}

      <Card className="mb-8">
        <div className="p-6">
          <div className="grid grid-cols-2 gap-4 mb-4">
            {user ? (
              <>
                <Button
                  onClick={logout}
                  className="bg-red-500 hover:bg-red-600 flex items-center justify-center py-2"
                >
                  <LucideIcons.LogOut size={18} className="mr-2" />
                  <span>로그아웃</span>
                </Button>
                <Button
                  onClick={toggleRecords}
                  className="bg-pink-500 hover:bg-pink-600 flex items-center justify-center py-2"
                >
                  {showRecords ? <LucideIcons.ChevronUp size={18} className="mr-2" /> : <LucideIcons.ChevronDown size={18} className="mr-2" />}
                  <span>{showRecords ? '기록 닫기' : '기록 보기'}</span>
                </Button>
              </>
            ) : (
              <Button
                onClick={login}
                className="bg-green-500 hover:bg-green-600 col-span-2 flex items-center justify-center py-2"
              >
                <LucideIcons.LogIn size={18} className="mr-2" />
                <span>구글 로그인</span>
              </Button>
            )}
            <Button
              onClick={() => handleLotteryTypeChange('lotto')}
              className={`flex items-center justify-center py-2 ${lotteryType === 'lotto' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              <LucideIcons.Clover size={18} className="mr-2" />
              <span>로또</span>
            </Button>
            <Button
              onClick={() => handleLotteryTypeChange('pension')}
              className={`flex items-center justify-center py-2 ${lotteryType === 'pension' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              <LucideIcons.PiggyBank size={18} className="mr-2" />
              <span>연금복권</span>
            </Button>
          </div>


          {image ? (
            <div className="w-full h-64 mb-4 overflow-hidden rounded-lg">
              <img
                src={image}
                alt="Uploaded"
                className="w-full h-full object-contain"
                loading="lazy"
              />
            </div>
          ) : (
            <div className="w-full h-64 flex items-center justify-center bg-gray-200 rounded-lg mb-4">
              <LucideIcons.Upload size={48} className="text-gray-400" />
            </div>
          )}
          <p className="text-center mb-12 text-xl">
            {image ? "새 이미지를 업로드하려면 아래 버튼을 클릭하세요" : "최애의 이미지를 업로드하세요"}
          </p>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleImageUpload}
            accept="image/*"
            className="hidden"
            id="imageUpload"
          />
          <Button
            onClick={handleNewImageUpload}
            className="bg-yellow-400 hover:bg-yellow-500 text-purple-700 w-full flex items-center justify-center py-2"
            disabled={isModelLoading || !isModelReady}
          >
            <LucideIcons.Image size={18} className="mr-2" />
            <span>
              {isModelLoading ? "모델 로딩 중..." : !isModelReady ? "모델 준비 중..." : image ? "새 이미지 선택" : "이미지 선택"}
            </span>
          </Button>
        </div>
      </Card>

      {lotteryNumbers && (
        <Card className="mb-8">
          <div className="p-6">
            <h2 className="text-xl font-semibold mb-4 text-center">🎉 당신의 최애가 뽑아준 번호</h2>
            <LotteryNumbers numbers={lotteryNumbers} type={lotteryType} />
            {showNotice && (
              <p className="text-sm text-gray-500 mt-2">
                <LucideIcons.Info className="inline-block mr-1" size={16} />
                참고: 중복 번호 제거를 위해 같은 이미지에서도 1-2개의 번호가 달라질 수 있습니다.
              </p>
            )}
            <div className="flex flex-col space-y-2 mt-4">
              <Button
                onClick={copyToClipboard}
                className="bg-blue-500 hover:bg-blue-600 w-full flex items-center justify-center py-2"
              >
                <LucideIcons.Copy size={18} className="mr-2" />
                <span>{copySuccess ? '복사됨!' : '번호 복사'}</span>
              </Button>
              <Button
                onClick={shareResults}
                className="bg-green-500 hover:bg-green-600 w-full flex items-center justify-center py-2"
              >
                <LucideIcons.Share2 size={18} className="mr-2" />
                <span>결과 공유하기</span>
              </Button>
            </div>
          </div>
        </Card>
      )}

      {user && showRecords && (
        <Card className="mb-8">
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">최근 기록</h3>
            {isLoadingRecords ? (
              <div className="flex justify-center items-center h-20">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
              </div>
            ) : records.length > 0 ? (
              <ul className="space-y-2">
                {records.map((record) => (
                  <li key={record.id} className="bg-purple-700 rounded p-2">
                    <span className="block text-sm text-yellow-300">
                      {record.createdAt instanceof Date
                        ? record.createdAt.toLocaleString()
                        : new Date(record.createdAt.seconds * 1000).toLocaleString()}
                    </span>
                    <span className="font-semibold">
                      {Array.isArray(record.numbers)
                        ? record.numbers.join(', ')
                        : `${record.numbers[0]} ${record.numbers[1]}`}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>기록이 없습니다.</p>
            )}
          </div>
        </Card>
      )}

      {!user && (
        <p className="text-center text-yellow-200 mt-4 mb-8">
          로그인하시면 번호 생성 기록을 저장하고 언제든지 조회할 수 있습니다.
        </p>
      )}

      {error && (
        <Alert className="mb-8" title="오류" description={error} />
      )}

      {isGenerating && (
        <Card className="mb-8">
          <div className="p-4">
            <Progress value={progress} className="w-full mb-2" />
            <p className="text-center">최애의 힘으로 번호를 생성 중...</p>
          </div>
        </Card>
      )}
    </div>
  ), [user, showRecords, login, logout, toggleRecords, lotteryNumbers, lotteryType, copyToClipboard, copySuccess, shareResults, image, handleNewImageUpload, isModelLoading, isModelReady, handleLotteryTypeChange, records, isLoadingRecords, error, isGenerating, progress, infoMessage, showNotice]);

  if (isInAppBrowser) {
    return (
      <div className="min-h-screen bg-white p-4 flex flex-col items-center justify-center text-center">
        <h2 className="text-2xl font-bold mb-4">인앱브라우저 호환문제로 인해<br />외부 브라우저로 접속해야 합니다.</h2>
        <p className="mb-4 text-gray-600">아래 버튼을 눌러 외부 브라우저를 실행해주세요.</p>
        <p className="mb-4 text-gray-600">네이버 브라우저도 지원되지 않습니다 .</p>
        <Button
          onClick={handleExternalBrowser}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded"
        >
          외부 브라우저로 열기
        </Button>
        <img
          src="https://tistory3.daumcdn.net/tistory/1893869/skin/images/inappbrowserout.jpeg"
          alt="외부 브라우저 안내"
          className="mt-8 w-4/5 max-w-md"
        />
      </div>
    );
  }

  if (isModelLoading) {
    return <LoadingPage progress={modelLoadingProgress} stage={modelLoadingStage} />;
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-purple-500 to-indigo-600 text-white'} p-4`}>
      <div className="flex flex-col items-center space-y-4">
        {/* PC용 상단 광고 */}
        <div className="w-full flex justify-center">
          {isMobile ? (
            <KakaoAd unit="DAN-Oq9Ks3exWwHdkoc7" width="320" height="100" />
          ) : (
            <KakaoAd unit="DAN-RMdBlHVHssuTs93a" width="728" height="90" />
          )}
        </div>

        <div className="w-full flex justify-center">
          <AdBanner
            imageUrl="/neon_runners.jpeg"
            linkUrl="https://discord.gg/neonrunners"
            altText="Neon Runners Ad"
          />
        </div>


        {/* 메인 콘텐츠 */}
        <div className="w-full flex justify-center items-center">
          {mainContent}
        </div>

        {/* 하단 광고 */}
        <div className="w-full flex justify-center">
          {isMobile ? (
            <KakaoAd unit="DAN-UP0qHIpVvOTYJ02P" width="320" height="100" />
          ) : (
            <KakaoAd unit="DAN-gGPZ0R7hhlTLYcd6" width="728" height="90" />
          )}
        </div>
      </div>

      {/* 다크 모드 토글 버튼 (변경 없음) */}
      <button
        onClick={toggleDarkMode}
        className="fixed bottom-4 right-4 bg-gray-200 dark:bg-gray-700 p-2 rounded-full shadow-lg"
        aria-label={isDarkMode ? "라이트 모드로 전환" : "다크 모드로 전환"}
      >
        {isDarkMode ? '🌞' : '🌙'}
      </button>

      {/* ShareOptions 모달 (변경 없음) */}
      {showShareOptions && (
        <ShareOptions
          onClose={() => setShowShareOptions(false)}
          lotteryNumbers={lotteryNumbers}
          serviceUrl="https://oshinonumber.site"
          lotteryType={lotteryType}
        />
      )}
    </div>
  );
};

export default React.memo(App);