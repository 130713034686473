import React from 'react';

const AdBanner = ({ imageUrl, linkUrl, altText }) => {
  const handleClick = () => {
    window.open(linkUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div 
      className="cursor-pointer hover:opacity-90 transition-opacity duration-300 mb-4 mx-auto w-full sm:w-[320px] md:w-[728px] lg:w-[874px]"
      onClick={handleClick}
    >
      <img 
        src={imageUrl} 
        alt={altText} 
        className="w-full h-auto object-cover rounded-lg shadow-lg"
        loading="lazy"
      />
    </div>
  );
};

export default AdBanner;